<template>
  <section id="overview" class="overview-section">
	<div class="overview-section-wrapper">
		<feature-card :move="!mobile" :value="toTop" :opacity="opacity" icon="shield-alt" title="Protect your Privacy" text="Create unique email addresses for each of your online accounts to stop companys following you around."/>
		<feature-card icon="sliders-h" :opacity="opacity" title="Regain control" text="If you receive too much junk or you don't use a service anymore, just deactivate the the address!"/>
		<feature-card :move="!mobile" :value="toTop" :opacity="opacity" icon="stopwatch" title="Temporary Addresses" text="Only need to use an address once? - No Problem, create a temporary address!"/>
	</div>
	<button class="primary-button signup-button" @click="notify">Get notified</button>
  </section>
</template>

<script>
	import FeatureCard from '@/components/atoms/FeatureCard'

    export default {
        name: 'Overview',
		data(){
			return {
				toTop: this.mobile ? 0 : 150,
				opacity: 0.5
			}
		},
        components: {
			FeatureCard
		},
		computed: {
			mobile: function(){
				return this.$store.state.mobile;
			},
		},
		methods: {
			notify(){
				window.location.href = 'https://app.mailpal.cc/notify'
			},
            handleScroll(){
				let toTop = window.scrollY;
				let newValue =  150 - ( toTop / 3 );
				if(!this.mobile){
					this.toTop = (newValue >= 0) ? newValue : 0;
				}else{
					this.toTop = 0;
				}
				
				let newOpacity = 1 - (newValue / 150);
				if(newOpacity > 1){
					this.opacity = 1;
				}else if(newOpacity > 0.5){
					this.opacity = newOpacity
				}
            }
        },
        created (){
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed(){
            window.removeEventListener('scroll', this.handleScroll);
        }
        
    }
</script>

<style scoped>
	.overview-section{
		text-align: center;
		margin-bottom: 5rem;
		margin-top: 3rem;
	}

	.overview-section-wrapper{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
		max-width: 1200px;
		margin: auto;
		width: 95%;
		text-align: left;
	}

	.signup-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 24px;
        border-bottom: 5px solid var(--primary-dark);
        margin-top: 2rem;
    }

	@media only screen and (max-width: 1200px) {
        .overview-section-wrapper{
			flex-direction: column;
			align-items: center;
			max-width: 500px;
			width: 70%;
		}
    }
</style>