<template>
  <div class="feature-text">
    <h1>{{title}}</h1>
    <slot></slot>
  </div>
</template>

<script>
    export default {
        name: 'FeatureText',
        props: {
            title: String
        },
    }
</script>

<style scoped>
  .feature-text h1{
      font-size: 30px;
      max-width: 380px;
  }

  .feature-text p{
    color: var(--font-lighter);
    max-width: 500px;
  }

  @media only screen and (max-width: 1200px) {
    .feature-text{
      text-align: center;
    }

    .feature-text h1{
      max-width: 100%;
    }

    .feature-text p{
      max-width: 600px;
      width: 100%;
      margin: auto;
    }
  }
</style>