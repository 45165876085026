<template>
  <section class="new-address-section-wrapper">
    <div class="new-address-section">
        <!--<svg xmlns="http://www.w3.org/2000/svg" id="bubbleSvg" width="883.864" height="370.165" viewBox="0 0 883.864 370.165"><path class="bubble" d="M735.511,0C604.672-70.374,151.6-89.639,2.852-16.639-73.6,20.883-107.908,94.2-30.274,126.406c73.1,30.322,253.959,19.488,334.274,55.712C469.254,256.652,515.12,354.5,663.87,281.5S866.351,70.374,735.511,0Z" transform="translate(74.176 62.918)"/></svg>-->
        <div class="new-address-left">
            <div class="address">
                <p>{{current}}</p>
                <span>@mailpal.cc</span>
                <font-awesome-icon icon="plus" />
            </div>
        </div>
        <div class="new-address-right">
            <feature-text title="Quickly add a new address">
                <p>Add a new address by just entering it's name or choosing a randomly generated one. And just like that all emails sent to it will be forwarded to your email account. Spam protection included.</p>
                <p>And the best part: <span style="color: var(--primary)">unlimited</span> number of addresses</p>
            </feature-text>
        </div>
    </div>
  </section>
</template>

<script>
    import FeatureText from '@/components/atoms/FeatureText'

    export default {
        name: 'NewAddress',
        data(){
            return {
                current: "",
                lastIndex: [],
                addresses: [ "richard.hendrix", "piedpiper", "gavin.belson", "bachmania", "hooli", "bhabhd92j323", "richard22", "h00li", "big.head", "jared.dunn", "jared123", "monica.hall", "bertram.gilfoyle", "dinesh45" ]
            }
        },
        methods: {
            typeText(text){
                return new Promise((resolve) => {
                    this.current = "";
                    for(let i in text){
                        setTimeout(() => {
                            this.current += text[i];
                            if(i == (text.length - 1)){
                                resolve()
                            }
                        }, 100 * i);
                    }
                })
            },
            selectAddress: async function(){
                let index = this.selectIndex()
                await this.typeText(this.addresses[index])
                await this.timer(3000)
                this.selectAddress()
               
            },
            selectIndex(){
                let index = 0;
                if(this.lastIndex.length === this.addresses.length){
                    this.lastIndex = [];
                }
                let run = true;
                while(run){
                    index = Math.floor(Math.random() * ((this.addresses.length - 1) - 0 + 1)) + 0;
                    if(!this.lastIndex.includes(index)){
                        this.lastIndex.push(index);
                        run = false;
                    }
                }
                return index
            },
            timer: function (ms) {
                return new Promise(res => setTimeout(res, ms));
            },
        },
        components: {
            FeatureText
        },
        mounted(){
            this.selectAddress()
        }
    }
</script>

<style scoped>
    .new-address-section-wrapper{
		margin-top: 10rem;
		margin-bottom: 5rem;
        position: relative;
	}

    .new-address-section{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		max-width: 1100px;
		margin: auto;
		width: 95%;
	}

    .new-address-left{
		width: 50%;
        margin-right: 3rem;
        position: relative;
	}

	.new-address-right{
		width: 50%;
        margin-left: 3rem;
	}

    .address{
        display: flex;
        align-items: center;
        background: var(--background);
        border-radius: 10px;
        padding: 5px 20px;
        width: 300px;
        height: 40px;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
        font-weight: 700;
        margin: auto;
    }

    .address p{
        margin-right: 5px;
    }

    .address span{
        color: var(--font-lighter);
        overflow-x: hidden;
    }

    .address svg{
        color: var(--primary);
        font-size: 25px;
        margin-left: auto;
        padding-left: 10px;
        cursor: pointer;
    }

    .bubble{
        fill: var(--orange);
    }

    #bubbleSvg{
        position: absolute;
        top: -30px;
        left: 25px;
        z-index: -1;
    }

    @media only screen and (max-width: 1200px) {
        .new-address-section-wrapper{
            margin-top: 5rem;
			margin-bottom: 2rem;
        }

		.new-address-section{
			flex-direction: column-reverse;
            width: 80%;
		}

        .new-address-right,
        .new-address-left{
            width: 100%;
            margin: auto;
        }

        .new-address-left{
            margin-top: 1rem;
        }

        .address{
            padding: 5px 17px;
            max-width: 300px;
            width: 85%;
        }

	}

	@media only screen and (max-width: 1000px) {
		.new-address-section h1{
			font-size: 25px;
		}
	}
</style>