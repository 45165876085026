<template>
    <div class="question-item" @click="expanded = !expanded">
        <hr>
        <div class="question-normal">
            <h3>{{title}}</h3>
            <font-awesome-icon icon="caret-down" :class="{ 'icon-flip': expanded}" />
        </div>
        <transition name="expand">
            <div v-if="expanded" class="expanded">
                <slot></slot>
            </div>
        </transition>
    </div>   
</template>

<script>
    export default {
        name: 'QuestionItem',
        data (){
            return {
               expanded: false
            }
        },
        props: {
            title: String
        },
    }
</script>

<style scoped>

    .question-item{
        width: 100%;
    }

    .question-item hr{
        color: var(--font)
    }

    .expanded{
        max-height: 700px;
        color: var(--font-light);
    }

    .question-normal{
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .question-normal svg{
        margin-left: auto;
        font-size: 25px;
        transition: all 0.3s;
    }
    
    .icon-flip{
        transform: rotate(180deg);
    }

    .expand-enter-active {
        animation: expand-in 0.4s;
    }
    .expand-leave-active {
        animation: expand-in 0.4s reverse;
    }

    .expand-leave-active .stats-mobile{
        opacity: 0;
    }

    .expand-enter-active .stats-mobile{
        opacity: 0;
    }

    @keyframes expand-in {
        0% {
            max-height: 0;
            opacity: 0;
        }
        20% {
            opacity: 0;
        }
        85% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }

    @media only screen and (max-width: 800px) {
        .question-normal h3{
            font-size: 15px;
        }
    }
</style>