<template>
    <div :class="(mobile) ? 'stats-mobile-item' : 'stats-item'">
        <font-awesome-icon :class="type + '-icon'" :icon="icon"/>
        <span>{{count}}</span>
    </div>
</template>

<script>
    export default {
        name: 'AddressStats',
        props: {
            type: String,
            count: Number,
            icon: String,
            mobile: {
                type: Boolean,
                default: false
            }
        }
    }
</script>

<style scoped>
    .stats-item{
        margin-left: 25px;
    }

    .stats-item span{
        background: var(--background-accent);
        color: var(--font-light);
        min-width: 20px;
        padding: 5px 5px;
        border-radius: 5px;
        display: inline-block;
        text-align: center;
    }

    .blocked-icon {
        color: var(--red);
        margin-right: 10px;
    }

    .forwarded-icon{
        color: var(--primary);
        margin-right: 10px;
    }

    @media only screen and (max-width: 1000px) {
        .stats-item{
            margin-left: 10px;
        }

        .blocked-icon,
        .forwarded-icon{
            margin-right: 5px;
        }

        .stats-item span{
            min-width: 15px;
            padding: 5px 2px;
        }
    }

    @media only screen and (max-width: 700px) {
        .stats-mobile-item{
            margin-left: 10px;
        }

        .stats-mobile-item span{
            background: var(--background-accent);
            color: var(--font-light);
            min-width: 15px;
            padding: 5px 2px;
            border-radius: 5px;
            display: inline-block;
            text-align: center;
        }
    }
</style>