<template>
	<section id="features" class="preview-section">
		<div class="preview-left">
			<h1>Beatiful and easy to use Web-Interface</h1>
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="205.82" height="201.717" viewBox="0 0 205.82 201.717"><defs><linearGradient id="gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox"><stop offset="0" stop-color="#b1a1f8"/><stop offset="0.5" stop-color="#c471ed"/><stop offset="1" stop-color="#e17079"/></linearGradient></defs><path class="a" d="M27.394,16A11.224,11.224,0,0,0,16,27.022a119.121,119.121,0,0,0,36.683,85.731,127.441,127.441,0,0,0,88.653,35.472l-18.231,13.222a10.781,10.781,0,0,0-2.279,15.426,11.652,11.652,0,0,0,15.952,2.2l45.577-33.056a10.8,10.8,0,0,0,0-17.63L136.778,95.336a11.556,11.556,0,0,0-6.829-2.2,11.369,11.369,0,0,0-10.815,7.531,10.811,10.811,0,0,0,3.972,12.3l18.231,13.222c-56.635,0-102.548-44.4-102.548-99.168A11.224,11.224,0,0,0,27.394,16ZM141.336,142.718A121.644,121.644,0,0,1,56.71,108.858,113.7,113.7,0,0,1,21.7,27.022a5.7,5.7,0,0,1,11.394,0c0,57.812,48.463,104.677,108.245,104.677h8.546a2.842,2.842,0,0,0,2.7-1.884,2.7,2.7,0,0,0-.993-3.075L126.523,108.56a5.391,5.391,0,0,1-1.139-7.713,5.826,5.826,0,0,1,7.976-1.1L178.937,132.8a5.4,5.4,0,0,1,0,8.815L133.36,174.672a5.826,5.826,0,0,1-7.976-1.1,5.391,5.391,0,0,1,1.139-7.713l25.067-18.181a2.7,2.7,0,0,0,.993-3.075,2.842,2.842,0,0,0-2.7-1.884Z" transform="translate(-19.396 29.691) rotate(-14)"/></svg>
		</div>
		<div class="preview-right">
			<img src="../../../public/preview.png" alt="Dashboard Preview">
		</div>
	</section>
</template>

<script>
    export default {
        name: 'DashboardPreview',
    }
</script>

<style scoped>
	.preview-section{
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		max-width: 1200px;
		margin: auto;
		margin-top: 10rem;
		margin-bottom: 5rem;
		width: 95%;
	}

	.preview-left{
		width: 50%;
		text-align: center;
	}

	.preview-left h1{
		max-width: 340px;
		margin: auto;
		font-size: 30px;
	}

	.preview-right{
		width: 50%;
	}

	.preview-right img{
		width: 100%;
	}

	.a{
		fill: url(#gradient);
	}

	@media only screen and (max-width: 1200px) {
		.preview-section{
			flex-direction: column;
			margin-top: 5rem;
			margin-bottom: 2rem;
		}

		.preview-left svg{
			display: none;
		}

		.preview-right{
			width: 80%;
		}

		.preview-right img{
			margin-top: 2rem;
		}
	}

	@media only screen and (max-width: 1000px) {
		.preview-left h1{
			font-size: 25px;
		}
	}
</style>