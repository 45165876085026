<template>
    <section class="categories-section">
        <div class="categories-left">
            <div class="category-list" :style="{ '--toTop': toTop + 'px' }" ref="categoryList">
                <Category v-bind="{ name: 'Social', color: 'var(--red)' }" :mock="true"/>
                <Category v-bind="{ name: 'Newsletters', color: 'var(--orange)' }" :mock="true" class="scroll-category" />
                <Category v-bind="{ name: 'Important', color: 'var(--purple)' }" :mock="true" class="scroll-category" />
                <Category v-bind="{ name: 'People', color: 'var(--blue)' }" :mock="true" class="scroll-category" />
                <div class="ellipsis-wrapper">
                    <font-awesome-icon icon="ellipsis-h" />
                </div>
                <Category v-bind="{ name: 'Your custom category', color: 'var(--font-dark)' }" :mock="true"/>
            </div>
        </div>
        <div class="categories-right">
            <feature-text title="Organize your addresses with categories">
                <p>Create categories with custom labels and colors to group addresses together. Add, remove and change categories at any time.</p>
            </feature-text>
        </div>
    </section>
</template>

<script>
    import FeatureText from '@/components/atoms/FeatureText'
    import Category from '@/components/Category'

    export default {
        name: 'Categories',
        data(){
			return {
				toTop: 50,
			}
		},
        components: {
            FeatureText,
            Category
        },
        methods: {
            handleScroll(){
				const toTop = this.$refs.categoryList.getBoundingClientRect().top;
                const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

                let diff = 50 - (( height - toTop ) / 7);

                if(diff > 0){
                    this.toTop = (diff <= 50) ? diff : 50;
                }else{
                    this.toTop = 0;
                }
            }
        },
        created (){
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed(){
            window.removeEventListener('scroll', this.handleScroll);
        }
    }
</script>

<style scoped>
    .categories-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        margin: auto;
        margin-top: 10rem;
        margin-bottom: 5rem;
        width: 95%;
    }

    .categories-left {
        width: 50%;
        margin-right: 3rem;
    }

    .categories-right {
        width: 50%;
        margin-left: 3rem;
    }

    .category-list{
        width: 300px;
        margin: auto;
    }

    .ellipsis-wrapper{
        text-align: center;
        margin-bottom: 0.5rem;
    }

    .scroll-category{
        margin-top: var(--toTop)
    }

    @media only screen and (max-width: 1200px) {
        .categories-section {
            flex-direction: column-reverse;
            max-width: 800px;
            width: 80%;
            margin: auto;
            margin-top: 5rem;
            margin-bottom: 2rem;
        }

        .categories-left {
            width: 100%;
            margin: auto;
            margin-top: 2rem;
        }

        .categories-right {
            width: 100%;
            margin: auto;
        }
    }

</style>