<template>
    <div id="landing">
        <Header />
        <main>
            <hero />
            <dashboard-preview />
            <overview />
            <new-address />
            <control-address />
            <categories />
            <stats />
            <pricing />
            <planned />
            <frequent-questions />
        </main>
        <Footer />
    </div>
</template>

<script>
    import Header from '@/components/Header'
    import Footer from '@/components/Footer'
    import Hero from '@/components/sections/Hero'
    import Overview from '@/components/sections/Overview'
    import DashboardPreview from '@/components/sections/DashboardPreview'
    import NewAddress from '@/components/sections/NewAddress'
    import ControlAddress from '@/components/sections/ControlAddress'
    import Categories from '@/components/sections/Categories'
    import Stats from '@/components/sections/Stats'
    import Pricing from '@/components/sections/Pricing'
    import FrequentQuestions from '@/components/sections/FrequentQuestions'
    import Planned from '@/components/sections/Planned'
    import { Global_Mutation } from '../store/types'

    export default {
        name: 'Landing',
        components: {
            Header,
            Footer,
            Hero,
            Overview,
            DashboardPreview,
            NewAddress,
            ControlAddress,
            Categories,
            Stats,
            Pricing,
            FrequentQuestions,
            Planned
        },
        computed: {
            mobile: function(){
                return this.$store.state.mobile;
            }
        },
        methods: {
            signUp (){
                window.location.href = 'https://app.mailpal.cc/signup'
            }
        },
        created(){
            document.body.parentNode.style.background = "var(--background-landing)";
            document.title = "MailPal - Protect your Privacy and Inbox";
            addEventListener('resize', () => {
                this.$store.commit(Global_Mutation.CHANGE_MOBILE, innerWidth <= 1200);
            })
        }
    }
</script>

<style>
</style>