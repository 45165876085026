<template>
  <div class="feature-overview-card" :class="{ 'move-card': move }" :style="{ '--toTop': value + 'px', '--opacityTop': 1 }">
    <font-awesome-icon :icon="icon" />
    <h1>{{title}}</h1>
    <p>{{text}}</p>
  </div>
</template>

<script>
    export default {
        name: 'FeatureCard',
        props: {
            icon: String,
            title: String,
            text: String,
            move: Boolean,
            value: {
                required: false,
                type: Number
            },
            opacity: {
                required: false,
                type: Number
            },
        }
    }
</script>

<style scoped>
  .feature-overview-card{
        padding: 15px 30px;
        background: var(--background);
        margin: 1rem;
        border-radius: 10px;
        height: 300px;
        max-width: 280px;
        width: 100%;
        opacity: var(--opacityTop);
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  }

    .feature-overview-card svg{
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        font-size: 35px;
        color: var(--purple);
    }

  .feature-overview-card h1{
      margin: 0;
  }

  .feature-overview-card p{
      margin: 0;
      margin-top: 1rem;
  }

  .move-card{
      transform: translate3d(0px, var(--toTop), 0px);
  }

  @media only screen and (max-width: 1200px) {
        .feature-overview-card{
             max-width: 80%;
        }

        .feature-overview-card h1{
            font-size: 30px;
        }

        .feature-overview-card p{
            font-size: 17px;
        }
    }

    @media only screen and (max-width: 800px) {

        .feature-overview-card{
            padding: 15px 20px;
            height: 230px;
        }

        .feature-overview-card h1{
            font-size: 26px;
        }

        .feature-overview-card p{
            font-size: 15px;
        }

        .feature-overview-card svg{
            margin-top: 1rem;
            margin-bottom: 1rem;
            font-size: 32px;
        }
    }

     @media only screen and (max-width: 500px) {

        .feature-overview-card{
            height: 200px;
        }

        .feature-overview-card h1{
            font-size: 20px;
        }

        .feature-overview-card p{
            font-size: 14px;
        }

        .feature-overview-card svg{
            font-size: 30px;
        }
    }
</style>