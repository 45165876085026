<template>
    <section class="control-address-section">
        <div class="control-address-left">
            <feature-text title="Deactivate an existing address with just one click">
                <p>You choose what addresses you want to connect to your personal email account. If an email is sent to
                    a deactivated address, it is either blocked and an error is returned to the sender, or it is
                    silently dropped. You choose.</p>
            </feature-text>
        </div>
        <div class="control-address-right">
            <font-awesome-icon icon="cloud" id="cloudIcon" />
            <div class="connection-blocked-wrapper">
                <p>MailPal</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213 85.583"><g transform="translate(-1218 -3011.345)"><rect class="ab" width="213" height="12" rx="3" transform="translate(1218 3048)"/><path class="bb" d="M56.193,23.774H36.742V4.323A4.323,4.323,0,0,0,32.419,0H28.1a4.323,4.323,0,0,0-4.323,4.323V23.774H4.323A4.323,4.323,0,0,0,0,28.1v4.323a4.323,4.323,0,0,0,4.323,4.323H23.774V56.193A4.323,4.323,0,0,0,28.1,60.516h4.323a4.323,4.323,0,0,0,4.323-4.323V36.742H56.193a4.323,4.323,0,0,0,4.323-4.323V28.1A4.323,4.323,0,0,0,56.193,23.774Z" transform="translate(1281.902 3054.136) rotate(-45)"/></g></svg>
            </div>
            <div class="email-client">
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="auto" viewBox="0 0 306 179">
                    <g transform="translate(-1472 -2961)">
                        <path class="a" d="M0,0H295a0,0,0,0,1,0,0V156a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V0A0,0,0,0,1,0,0Z"
                            transform="translate(1472 2979)" />
                        <path class="b" d="M5,0H290a5,5,0,0,1,5,5V26a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5A5,5,0,0,1,5,0Z"
                            transform="translate(1472 2971)" />
                        <line class="c" x2="295" transform="translate(1472 3021.405)" />
                        <line class="c" x2="295" transform="translate(1472 3044.017)" />
                        <line class="c" x2="295" transform="translate(1472 3068.566)" />
                        <line class="c" x2="295" transform="translate(1472 3093.116)" />
                        <line class="c" x2="295" transform="translate(1472 3117.666)" />
                        <circle class="d" cx="3.5" cy="3.5" r="3.5" transform="translate(1484 3007)" />
                        <circle class="e" cx="3.5" cy="3.5" r="3.5" transform="translate(1484 3029)" />
                        <ellipse class="e" cx="3.5" cy="3" rx="3.5" ry="3" transform="translate(1484 3054)" />
                        <ellipse class="e" cx="3.5" cy="3" rx="3.5" ry="3" transform="translate(1484 3079)" />
                        <circle class="e" cx="3.5" cy="3.5" r="3.5" transform="translate(1484 3103)" />
                        <path class="b" d="M8.153,3l157.74,3.077,16.44,3.381L2.243,7.9Z"
                            transform="translate(1504.189 3003.868)" />
                        <path class="f" d="M8.153,3l157.74,3.077,16.44,3.381L2.243,7.9Z"
                            transform="translate(1504.189 3026.479)" />
                        <path class="f" d="M8.153,3l157.74,3.077,16.44,3.381L2.243,7.9Z"
                            transform="translate(1504.189 3051.029)" />
                        <path class="f" d="M8.153,3l157.74,3.077,16.44,3.381L2.243,7.9Z"
                            transform="translate(1504.189 3075.579)" />
                        <path class="f" d="M8.153,3l157.74,3.077,16.44,3.381L2.243,7.9Z"
                            transform="translate(1504.189 3100.128)" />
                        <circle class="g" cx="10" cy="10" r="10" transform="translate(1758 2961)" /></g>
                </svg>
            </div>
        </div>
    </section>
</template>

<script>
    import FeatureText from '@/components/atoms/FeatureText'

    export default {
        name: 'ControlAddress',
        components: {
            FeatureText
        }
    }
</script>

<style scoped>
    .control-address-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 1100px;
        margin: auto;
        margin-top: 10rem;
        margin-bottom: 5rem;
        width: 95%;
    }

    .control-address-left {
        width: 50%;
        margin-right: 3rem;
    }

    .control-address-right {
        width: 50%;
        margin-left: 3rem;
        position: relative;
        display: flex;
        align-items: center;
    }

    .connection-blocked-wrapper{
        margin-left: 1rem;
        margin-right: 1rem;
        text-align: center;
    }

    .connection-blocked-wrapper p{
        margin: 0;
    }

    .connection-blocked-wrapper svg{
        width: 200px;
        height: auto;
    }

    #cloudIcon{
        width: 120px;
        height: 120px;
        color: var(--font-lighter);
    }

    .email-client{
        max-width: 180px;
        width: 50%;
    }

    .ab{
        fill:#5b5b74;
    }
    
    .bb{
        fill:#e17079;
    }

    .a {
        fill: #6a6a84;
    }

    .b,
    .f {
        fill: #5b5b74;
    }

    .c {
        fill: none;
        stroke: #5b5b74;
        stroke-width: 2px;
    }

    .d,
    .e {
        fill: #b1a1f8;
    }

    .e {
        opacity: 0.16;
    }

    .f {
        opacity: 0.35;
    }

    .g {
        fill: #de616b;
    }

    .h {
        fill: #fff;
        font-size: 11px;
        font-family: SegoeUI-Bold, Segoe UI;
        font-weight: 700;
    }

    @media only screen and (max-width: 1200px) {
        .control-address-section{
            flex-direction: column;
            margin-top: 5rem;
            margin-bottom: 2rem;
        }

        .control-address-right{
            width: 80%;
            margin: auto;
            justify-content: center;
            margin-top: 2rem;
        }

        .control-address-left{
            width: 80%;
            margin: auto;
        }

        .email-client{
            width: 150px;
        }
    }

    @media only screen and (max-width: 800px) {
        #cloudIcon{
            width: 70px;
            height: 70px;
        }

        .connection-blocked-wrapper svg{
            width: 120px;
        }

        .email-client{
            width: 70px;
        }
    }
</style>