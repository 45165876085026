<template>
    <section class="questions-section">
        <div class="headline">
            <h1>Frequently Asked Questions</h1>
            <h2>Browse through the frequently asked questions</h2>
        </div>
        <div class="questions-wrapper">
            <question-item title="What is Email Forwarding?">
                <p>Email forwarding is the process of receiving an email with one address and forwarding it to another. In the context of <a href="/">MailPal</a>:</p>
                <p>Let's suppose Alice has a personal email account with the address alice@example.com. She also has a <a href="/">MailPal</a> address alice@mailpal.cc.</p>
                <p>Alice has a co-worker Bob, who has Alice's personal email address. Now in the beginning Bob would send a few emails per month.</p>
                <p>But now around Christmas, Bob starts constantly sending emails to Alice about his new holiday calender. Alice's inbox is slowly filling up and even after asking bob to stop, he still keeps sending her emails.</p>
                <p>Now if Alice would have given Bob her <a href="/">MailPal</a> address, she could just deactivate her address via the MailPal dashboard and all of Bobs emails would have been silently dropped.</p>
            </question-item>
            <question-item title="Why choose MailPal Email Forwarding?">
                <p><a href="/">MailPal</a> is secure, fast and cheap. For just 42 $ per year you get unlimited addresses and emails. MailPal helps you keep your inbox clean and protects you from companys following you around the web and selling your data. You can even create temporary addresses which expire after one use or after a specifc time period.</p>
                <p>Sounds good? Sign up <a href="/signup">here</a></p>
            </question-item>
            <question-item title="Does this work with email provider x?">
                <p>Yes. <a href="/">MailPal</a> uses SRS (<a href="https://de.wikipedia.org/wiki/Sender_Rewriting_Scheme">Sender Rewriting Scheme</a>) to forward a given email to it's target. SRS is a common way to forward emails and works with all major email providers.</p>
            </question-item>
            <question-item title="Do you read my emails?">
                <p>No. Why would we? Were are not interested, nor are we in the business of reading/selling your emails. In the end we want to sleep at night. We have a strict No-Logs-Policy (which you can read more about in our <a href="/privacy">Privacy Policy</a>). All emails are only stored on our system temporarily while forwarding it. Once the email is received it is automaticly deleted never to be seen again.</p>
                <p>If you have any other concerns or questions, feel free to contact us directly: <a href="mailto:hello@mailpal.cc">hello@mailpal.cc</a></p>
            </question-item>
            <div class="question-item link">
                <hr>
                <a class="nolink" href="/docs">
                    <div class="read-docs">
                        <h3>Read the Documentation</h3>
                        <font-awesome-icon icon="arrow-right" />
                    </div>
                </a>
            </div>   
        </div>
        <div class="contact">
            <h1>Can't find what your are looking for?</h1>
            <button class="primary-button hero-button" @click="navigateContact">Contact us</button>
        </div>
    </section>
</template>

<script>
    import QuestionItem from '@/components/atoms/QuestionItem'

    export default {
        name: 'FrequentQuestions',
        components: {
            QuestionItem
        },
        methods:{
            navigateContact (){
                window.location.href = 'https://app.mailpal.cc/contact'
            }
        }
    }
</script>

<style scoped>
    .questions-section {
        max-width: 1200px;
        margin: auto;
        margin-top: 5rem;
        margin-bottom: 5rem;
        width: 95%;
    }

    .headline{
        text-align: center;
        margin: auto;
    }

    .headline h1{
        font-size: 35px;
        margin: 0;
    }

    .headline h2 {
        color: var(--font-lighter);
        font-size: 20px;
        margin: 0;
        margin-top: 1rem;
    }

    .questions-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 3rem;
        width: 95%;
        max-width: 500px;
    }

    .question-item{
        width: 100%;
    }

    .nolink{
        cursor: pointer;
        color: var(--font);
        text-decoration: none;
    }

    .question-item hr{
        color: var(--font)
    }

    .read-docs{
        display: flex;
        align-items: center;
    }

    .read-docs svg{
        margin-left: auto;
        font-size: 25px;
        transition: all 0.3s;
    }

    a{
        color: var(--primary);
        text-decoration: none;
    }

    .contact{
        max-width: 1200px;
        margin: auto;
        margin-top: 3rem;
        margin-bottom: 5rem;
        width: 95%;
        text-align: center;
    }

    @media only screen and (max-width: 1200px) {
        .questions-section {
            margin-bottom: 2rem;
            width: 80%;
        }
    }

    @media only screen and (max-width: 800px) {
        .headline h1{
            font-size: 30px;
        }

        .headline h2 {
            font-size: 17px;
            margin-top: 0.5rem;
        }

        .read-docs h3{
            font-size: 15px;
        }
    }

</style>