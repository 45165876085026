<template>
    <section class="stats-section">
        <div class="stats-left">
            <feature-text title="See exactly how many emails were blocked and forwarded">
                <p>See at a glance which address is being used a lot, and which is successfully blocking emails to protect your inbox.</p>
            </feature-text>
        </div>
        <div class="stats-right">
            <div class="stats">
                <address-stats type="blocked" :count="4" icon="ban" />
                <address-stats type="forwarded" :count="13" icon="arrow-right" />
            </div>
        </div>
    </section>
</template>

<script>
    import FeatureText from '@/components/atoms/FeatureText'
    import AddressStats from '@/components/atoms/AddressStats'

    export default {
        name: 'Stats',
        components: {
            FeatureText,
            AddressStats
        },
    }
</script>

<style scoped>
    .stats-section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 1000px;
        margin: auto;
        margin-top: 10rem;
        margin-bottom: 5rem;
        width: 95%;
    }

    .stats-left {
        width: 50%;
        margin-right: 3rem;
    }

    .stats-right {
        width: 50%;
        margin-left: 3rem;
    }

    .stats{
        display: flex;
        justify-content: center;
    }

    @media only screen and (max-width: 1200px) {
        .stats-section {
            flex-direction: column;
            max-width: 1200px;
            width: 80%;
            margin: auto;
            margin-top: 5rem;
            margin-bottom: 2rem;
        }

        .stats-left {
            width: 100%;
            margin: auto;
        }

        .stats-right {
            width: 100%;
            margin: auto;
            margin-top: 2rem;
        }

        .stats{
            justify-content: center;
            margin-right: 2rem;
        }
    }

</style>