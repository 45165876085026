<template>
    <section id="pricing" class="pricing-section">
        <div class="headline">
            <h1>Simple Pricing</h1>
            <h2>No hidden fees, no bullsh*t</h2>
        </div>
        <div class="pricing-wrapper">
            <div class="pricing-item">
                <h1 class="pro">Pro</h1>
                <h2>Only one pricing tier</h2>
                <ul>
                    <li>unlimited addresses</li>
                    <li>unlimited emails per month</li>
                    <li>access to API</li>
                    <li>access to browser extension</li>
                </ul>
                <span class="pro">3.50 $ / month</span>
            </div>   
        </div>
        <div class="button-wrapper">
            <button class="primary-button signup-button" @click="notify">Get notified</button>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Pricing',
        data(){
			return {
				space: 50,
			}
		},
        methods: {
			notify(){
				window.location.href = 'https://app.mailpal.cc/notify'
			}
        }
    }
</script>

<style scoped>
    .pricing-section {
        max-width: 1200px;
        margin: auto;
        margin-top: 5rem;
        margin-bottom: 5rem;
        width: 95%;
    }

    .headline{
        text-align: center;
        margin: auto;
    }

    .headline h1{
        font-size: 35px;
        margin: 0;
    }

    .headline h2 {
        color: var(--font-lighter);
        font-size: 20px;
        margin: 0;
        margin-top: 1rem;
    }

    .pricing-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
    }

    .pricing-item{
        background: var(--background);
        padding: 15px 25px;
        border-radius: 10px;
        max-width: 250px;
        width: 80%;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
        height: 250px;
        position: relative;
    }

    .pricing-item h1{
        margin: 0;
        font-size: 32px;
    }

    .pricing-item h2{
        margin: 0;
        font-size: 20px;
        color: var(--font-lighter);
    }

    .pricing-item span{
        font-weight: 700;
        font-size: 20px;
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    .pricing-item ul{
        padding-left: 20px;
        margin-top: 20px;
    }

    .pro{
        color: var(--primary);
    }

    .button-wrapper{
        text-align: center;
    }

    .signup-button {
        background: var(--primary);
        color: var(--font-dark);
        font-size: 24px;
        border-bottom: 5px solid var(--primary-dark);
        margin-top: 3rem;
    }

</style>