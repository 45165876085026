<template>
    <section class="planned-section">
        <div class="headline">
            <h1>Planned Features</h1>
            <h2>We are just getting started!</h2>
        </div>
        <div class="items-wrapper">
            <div class="item">
                <div class="image-wrapper">
                    <font-awesome-icon :icon="['fab', 'chrome']" />
                    <font-awesome-icon :icon="['fab', 'firefox-browser']" />
                </div>
                <div class="item-text">
                    <h3>Browser extensions</h3>
                    <p>Browser extensions for both chrome and firefox so you can create new addresses even faster. Will be able to automaticly create a new address when you register an account on a website.</p>
                </div>
            </div>
            <div class="item">
                <div class="image-wrapper">
                    <font-awesome-icon icon="code" />
                </div>
                <div class="item-text">
                    <h3>Developer API</h3>
                    <p>Full access to everything you can do via the MailPal Dashboard through a modern REST API.</p>
                </div>
            </div>
            <div class="item">
                <div class="image-wrapper">
                    <font-awesome-icon icon="at" />
                </div>
                <div class="item-text">
                    <h3>Custom Domain</h3>
                    <p>Connect your own domain to MailPal and use it in exactly the same way as with mailpal.cc</p>
                </div>
            </div>
            <div class="item">
                <div class="image-wrapper">
                    <font-awesome-icon icon="server" />
                </div>
                <div class="item-text">
                    <h3>More Server locations</h3>
                    <p>MailPal currently only uses two servers hosted in Frankfurt, Germany. In the future we hope to use more servers around the world to make MailPal more redundant and even faster.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Planned'
    }
</script>

<style scoped>
    .planned-section {
        max-width: 1200px;
        margin: auto;
        margin-top: 5rem;
        margin-bottom: 5rem;
        width: 80%;
    }

    .headline{
        text-align: center;
        margin: auto;
    }

    .headline h1{
        font-size: 35px;
        margin: 0;
    }

    .headline h2 {
        color: var(--font-lighter);
        font-size: 20px;
        margin: 0;
        margin-top: 1rem;
    }

    .items-wrapper{
        margin: auto;
        margin-top: 3rem;
        width: 95%;
        max-width: 500px;
    }

    .item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .extension-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
    }

    .image-wrapper{
        margin-right: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .image-wrapper svg{
        height: 40px;
        width: 40px;
        color: var(--font-light);
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    .item-text h3{
        margin-bottom: 0;
    }

    .item-text p{
        margin-top: 10px;
        color: var(--font-light);
    }

    @media only screen and (max-width: 800px) {
        .headline h1{
            font-size: 32px;
        }

        .headline h2 {
            font-size: 17px;
            margin-top: 0.8rem;
        }

        .item-text h3{
            font-size: 17px;
        }

        .item-text p{
            margin-top: 5px;
            font-soze: 15px;
        }

        .image-wrapper{
            margin-right: 1rem;
        }

        .image-wrapper svg{
            height: 30px;
            width: 30px;
        }
    }
</style>