<template>
    <header class="header" :class="{ background: show }">
        <h1 class="logo hideOnLaptop" :class="{ show: show }">MailPal</h1>
        <div class="nav">
            <ul class="nav-list">
                <li class="hideOnMobile">
                    <a href="#overview">Overview</a>
                </li>
                <li class="hideOnMobile">
                    <a href="#pricing">Pricing</a>
                </li>
                <li>
                    <a href="https://app.mailpal.cc/login">Sign in</a>
                </li>
                <li class="standout">
                    <a href="https://app.mailpal.cc/notify">Get notified</a>
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
  export default {
    name: 'Header',
    data(){
        return {
            show: false
        }
    },
    methods: {
        handleScroll: function (){
            if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
                this.show = true;
            } else {
                this.show = false;
            }
        }
    },
    computed: {
        mobile: function(){
            return this.$store.state.mobile;
        },
    },
    created (){
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    }
  }
</script>

<style scoped>
    .header{
        padding: 1rem 0;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        transition: 0.2s ease-in-out;
        -webkit-transition: 0.2s ease-in-out;
        -moz-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        background: var(--background-landing);
    }

    .background {
        background: var(--background);
        box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
    }

    .nav{
        float: right;
        margin-right: 1.5rem;
    }

    .nav-list{
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .nav-list li {
        float: left;
        margin-left: 1rem;
        margin-right: 1rem;
        padding: 10px 10px;
        font-size: 18px;
    }

    .nav-list a{
        text-decoration: none;
        color: var(--font);
        font-weight: 600;
    }

    .standout a{
        color: var(--primary);
        font-weight: 700;
    }

    .logo{
        transition: 0.2s ease-in-out;
        -webkit-transition: 0.2s ease-in-out;
        -moz-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        opacity: 0;
        margin: 0;
        margin-left: 1.5rem;
        float: left;
    }

    .show{
        opacity: 1;
    }

    @media only screen and (max-width: 1300px) {
        .hideOnLaptop{
            display: none;
        }
    }

    @media only screen and (max-width: 900px) {
        .nav-list li {
            margin-right: 5px;
            padding: 5px 5px;
            font-size: 15px;
        }

        .hideOnLaptop{
            display: none;
        }
    }

    @media only screen and (max-width: 700px) {
        .hideOnMobile {
            display: none;
        }

        .standout{
            display: block;
            border-radius: 5px;
            padding: 10px 25px !important;
            cursor: pointer;
        }
    }
</style>
