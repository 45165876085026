<template>
    <section id="hero" class="hero-wrapper">
        <div class="hero-content">
            <h1>Protect your Privacy and Inbox with MailPal's email forwarding service.</h1>
            <p>Be the first to be notified when MailPal launches.</p>
            <div class="hero-form">
                <input v-model="email" placeholder="Your email address">
                <button class="primary-button hero-button" @click="notify">Notify me</button>
            </div>
            <span class="error" v-if="error.length > 0">{{ error }}</span>
            <span class="success" v-if="message.length > 0">{{ message }}</span>
        </div>
    </section>
</template>

<script>

    export default {
        name: 'Hero',
        data () {
            return {
                email: "",
                error: "",
                message: ""
            }
        },
        methods: {
            async notify (){
                if (this.email.length < 1) {
                    return this.error = "Please fill out the form above."
                }

                try {
                    const data = {
                        email: this.email
                    }

                    const endpoint = process.env.NODE_ENV === 'production' ? 'https://app.mailpal.cc/api/notify' : '/api/notify'

                    const result = await fetch(endpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })

                    if (!result.ok) {
                        return this.error = 'An error ocurred, please try again.'
                    }

                    const json = await result.json()

                    if (json.error !== undefined) {
                        return this.error = json.error || 'An error ocurred, please try again.'
                    }

                    this.error = ''
                    this.message = 'We will notify you once MailPal is ready!'

                } catch (err) {
                    console.log(err)
                    this.error = err.message || 'An error ocurred, please try again.'
                }
            }
        },
    }
</script>

<style>

    .hero-wrapper{
        width: 85%;
        max-width: 1000px;
        margin: auto;
        margin-top: 10rem;
        margin-bottom: 5rem;
    }

    .hero-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .hero-content h1{
        font-size: 3rem;
        margin-top: 0;
        margin-bottom: 0;
        font-family: 'Space Grotesk', sans-serif;
    }

    .hero-content p{
        margin-top: 1rem;
        font-size: 22px;
        color: var(--font-light);
    }

    .hero-content span{
        margin-top: 0.5rem;
        font-size: 17px;
    }

    .error {
        color: var(--red);
    }

    .success {
        color: var(--primary);
    }

    .hero-form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .hero-content input {
        border: none;
        outline: 0;
        box-shadow: none;
        background: var(--background-light);
        color: var(--font-light);
        font-size: 17px;
        margin-right: 1rem;
        padding: 10px 20px;
        height: 30px;
        width: 80%;
        max-width: 250px;
        border-radius: 5px;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--font-lighter);
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--font-lighter);
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--font-lighter);
    }

    .hero-button{
        background: var(--primary);
        color: var(--font-dark);
        font-size: 17px;
        height: 50px;
        border-bottom: 5px solid #63C6B1;
    }

    @media only screen and (max-width: 1200px) {
        .hero-content h1{
            font-size: 50px;
        }

        .hero-content p{
            font-size: 17px;
        }

        .hero-button{
            font-size: 18px;
            border-bottom: 4px solid #63C6B1;
        }

        .hero-content{
            margin-bottom: 2rem;
        }
    }

    @media only screen and (max-width: 700px) {
        .hero-content{
            margin-top: 1rem;
        }

        .hero-content h1{
            font-size: 30px;
        }

        .hero-content p{
            font-size: 15px;
        }

        .hero-button{
            font-size: 16px;
            border-bottom: 3px solid #63C6B1;
        }
    }
</style>