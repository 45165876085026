<template>
    <div class="category-item" :class="{'category-active': active}">
        <font-awesome-icon v-if="name.toLowerCase() === 'all'" class="category-icon" icon="home"/>
        <font-awesome-icon v-else-if="name.toLowerCase() === 'recent'" class="category-icon" icon="clock"/>
        <span v-else class="category-dot" :style="`background: ${color}`" />
        <p>{{name}}</p>
        <span v-if="mock != true && addresses != undefined" class="category-count">{{addresses.length}}</span>
    </div>
                
</template>

<script>
  export default {
    name: 'Category',
    props: {
        name: String,
        addresses: Array,
        color: String,
        _id: String,
        mock: {
            required: false,
            type: Boolean
        }
    },
    computed: {
        active: function(){
            if(this.mock){
                return false
            }
            let current = this.$store.state.category.currentCategory.id;
            return current === this._id;
        }
    }
  }
</script>

<style scoped>
    .category-item{
        background: var(--background);
        border-radius: 5px;
        height: 40px;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        cursor: pointer;
    }

    .category-item:hover{
        filter: brightness(108%);
    }

    .category-dot{
        width: 17px;
        height: 17px;
        border-radius: 17px;
        margin-left: 1rem;
        margin-right: 0.6rem;
    }

    .category-count{
        margin-left: auto;
        margin-right: 1rem;
        color: var(--font-light)
    }

    .category-active{
        background: var(--background-dark);
    }

    .category-icon{
        margin-left: 1rem;
        margin-right: 0.6rem;
    }
</style>